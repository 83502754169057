<template>
  <div>
    <!-- 全部记录0 -->
    <withdrawal-table :auditStatus="0" :isShow="true"></withdrawal-table>
  </div>
</template>
<script>
import WithdrawalTable from "@/components/supplier/withdrawalTable.vue";
export default {
  components: { WithdrawalTable },
  data() {
    return {
      
    };
  },

  methods: {
    
  }
};
</script>
<style lang="scss" scoped>

</style>